import * as React from 'react';
import { Headline, Seo, FavoritesCards, ModalOverlay } from '../components';
import { FlexRow } from '../components/app/app.styled';
import { graphql } from 'gatsby';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { isBrowser } from '../utils';
import { Button } from '@material-ui/core';

export const query = graphql`
  query Favorites {
    allFile(filter: { extension: { regex: "/(jpg)|(jpeg)|(png)/" } }) {
      edges {
        node {
          publicURL
        }
      }
    }
  }
`;

const FavoritesPage = ({ data }) => {
  const colorUrlMap = new Map(
    data.allFile.edges
      .map((item) => {
        let url = item.node.publicURL;
        let urlSplit = item.node.publicURL.split('/');
        let nameSplit = urlSplit[urlSplit.length - 1].split('-');
        if (nameSplit[0] === 'color') {
          return [urlSplit[urlSplit.length - 1], url];
        }
        return [];
      })
      .filter((entry) => entry[0])
  );

  const handleBackButton = () => {
    if (isBrowser()) {
      window.history.back();
    }
  };

  return (
    <>
      <ModalOverlay />
      <Seo title='Frame Dream Favorites' />

      <Headline className={'m__t__medium'}>
        <Button onClick={handleBackButton} startIcon={<ArrowBackIcon />}>
          BACK
        </Button>

        <Headline.Title>My Favorites</Headline.Title>
      </Headline>

      <FlexRow
        className={
          'm__l__medium m__r__medium layout__center layout__max-width layout__max-width-inner'
        }
      >
        <FavoritesCards colorUrlMap={colorUrlMap} />
      </FlexRow>
    </>
  );
};

export default FavoritesPage;
